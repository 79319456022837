import { gql } from '@apollo/client';

const MESSAGE_READ_STATUS_FRAGMENT = gql`
  fragment MessageReadStatusFragment on MessageReadStatus {
    id
    status
    last_read_message
    last_read_timestamp
    AppUser {
      id
    }
  }
`;

const BRANCH_FORM_FRAGMENT = gql`
  fragment BranchFormFragment on Form {
    id
    name
    Questions(order: "sort_index") {
      id
      title
      type
      sort_index
      QuestionCategories(limit: 1) {
        id
      }
      defaultAnswers {
        PetRecordId
        answer
        AnswerOptionId
      }
      updates_field
      ask_per_pet
      get_answers_from
      AnswerOptions {
        id
        title
      }
    }
  }
`;
export const RefreshToken = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(RefreshTokenInput: { refreshToken: $refreshToken }) {
      token
      refreshToken
    }
  }
`;

export const CheckEmailTaken = gql`
  mutation checkEmailAppUser($email: String!) {
    checkEmailAppUser(AppUserEmailInput: { email: $email }) {
      exists
    }
  }
`;

export const SignupUser = gql`
  mutation createAppUser($name: String!, $postcode: String!, $email: String!, $consent_time: Date!, $country_id: Float!) {
    createAppUser(AppUser: { email: $email, name: $name, postcode: $postcode, data_consent: true, comms_consent: false, consent_time: $consent_time, country_id: $country_id }) {
      message
    }
  }
`;

export const SendVerificationEmail = gql`
  mutation sendVerificationEmail($email: String!) {
    sendVerificationEmail(AppUserEmailInput: { email: $email }) {
      message
    }
  }
`;

export const VerifyUser = gql`
  mutation verifyAppUser($code: String!, $email: String!) {
    verifyAppUser(AppUserVerifyInput: { email: $email, code: $code }) {
      token
      firebaseToken
      refreshToken
    }
  }
`;

export const PetRecordEdit = gql`
  mutation petRecordEdit($id: String!, $branchId: String!, $vetName: String!) {
    petRecordEdit(PetRecord: { id: $id, BranchId: $branchId, vet_name: $vetName }) {
      id
    }
  }
`;

export const LeavePack = gql`
  mutation deleteAppUserChatConnection($chatroomId: String!) {
    deleteAppUserChatConnection(AppUserChatConnectionDeleteInput: { id: $chatroomId }) {
      message
    }
  }
`;

export const GetAllPets = gql`
  query getProfile {
    getProfile {
      id
      email
      name
      Pets(order: "name") {
        id
        name
        profile_pic
        birthdate
        type
        gender
        PetRecord {
          id
        }
      }
      ChappedPets(order: "name") {
        id
        name
        profile_pic
        birthdate
        gender
        PetRecord {
          id
        }
        AppUser {
          id
          profile_pic
        }
      }
    }
  }
`;

export const GetBranchCategories = gql`
  query getBranchCategories {
    branchCategoryGet(order: "name", where: { status: "PARENT" }) {
      id
      name
      picture
    }
  }
`;

export const KickUserFromPack = gql`
  mutation kickAppUserChatConnection($chatroomId: String!, $AppUserId: String!) {
    kickAppUserChatConnection(AppUserChatConnectionDeleteInput: { id: $chatroomId, AppUserId: $AppUserId }) {
      message
    }
  }
`;

export const GetChaps = gql`
  query chapGet($pets: [String]) {
    chapGet(where: { PetId: { in: $pets } }) {
      id
      end_date
      PetId
      AppUserId
    }
  }
`;

export const BranchDetailsProductCategoryGet = gql`
  query getProductsByProductCategories($branchId: String) {
    getProductsByProductCategories(GetProductsByProductCategoriesInput: { BranchId: $branchId, status: ["IN_STOCK", "OUT_OF_STOCK"] }) {
      name
      id
      ProductCategoryItems {
        id
        sort_index
      }
      Products {
        id
        name
        BranchId
        sort_index
        price
        charge_type
        description
        duration
        unit
        type
        photos
        ProductKinds {
          id
          name
        }
        customizations
        Branch {
          id
        }
        cta_text
        Flags {
          id
          name
        }
        status
        stock
      }
    }
  }
`;

export const ChangePackName = gql`
  mutation chatRoomEdit($id: String!, $name: String!) {
    chatRoomEdit(ChatRoom: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

export const DeleteSuppRecord = gql`
  mutation deleteSuppRecord($id: String!) {
    deleteSuppRecord(SuppRecordDeleteInput: { id: $id }) {
      message
    }
  }
`;

export const DeleteVaccRecord = gql`
  mutation deleteVaccRecord($id: String!) {
    deleteVaccRecord(VaccRecordDeleteInput: { id: $id }) {
      message
    }
  }
`;

export const GetChatRooms = gql`
  ${MESSAGE_READ_STATUS_FRAGMENT}
  query getChatRooms {
    getChatRooms(limit: 100) {
      AppUsers {
        name
        id
        profile_pic
      }
      BusUsers {
        name
        id
        profile_pic
      }
      name
      id
      location
      cover_photo
      createdAt
      created_by
      Messages(order: "timestamp") {
        id
        body
        notification_sent
        timestamp
        user_id
        user_type
        photos
        location
        video
      }
      MessageReadStatuses {
        ...MessageReadStatusFragment
      }
    }
  }
`;

export const GetChatRoom = gql`
  query chatRoomGet($id: String!) {
    chatRoomGet(where: { id: $id }) {
      AppUsers {
        name
        id
        profile_pic
      }
      BusUsers {
        name
        id
        profile_pic
      }
      name
      id
      location
      cover_photo
      createdAt
      created_by
    }
  }
`;

export const GetProducts = gql`
  query productGet($cartIds: [String]) {
    productGet(where: { id: $cartIds, status: "IN_STOCK" }) {
      id
      name
      type
      unit
      price
      charge_type
      weight
      ProductKinds {
        name
      }
      Branch {
        id
        name
      }
      customizations
      stock
    }
  }
`;
const GetPetRecordPayload = `{
  id, vet_name, microchip_number, microchip_provider, neutred, passport_number, shape, allergies, chronic_conditions, disabilities, measurements
  Branch { id, name, email, address, contact_number, opening_hours, latitude, longitude, photos, colors },
  Branches { id, name, email, address, contact_number, opening_hours, latitude, longitude, photos, colors },
  Meals { id, time, quantity, unit, Branch { id, name } },
  SuppRecords { id, time, quantity, type, adherences_allowed, start_date, Product { id, name, weight, unit, Branch {id, name}, ProductKinds { id, name } } },
  VaccRecords { id, name, dates, nextdate, color, VaccRecordType { id, name } }
  MedConds(order: "reverse:updatedAt") {
    id, dates, name, description, createdAt, 
    Notes(order: "reverse:timestamp") {
      id, timestamp, body, BusUser {id, name} 
    },
    SuppRecords(order: "reverse:updatedAt") {
      id, quantity, time, adherences_allowed,
      Product {
        id, name, weight, unit, ProductKinds { id, name } 
      }
    },
    Adherences {
      id
      SuppRecord(order: "reverse:updatedAt") {
        id, quantity, time, adherences_allowed,
        Product {
          id, name, weight, unit, ProductKinds { id, name } 
        }
      },
    }
    VaccRecords { id, name, dates, nextdate, color, VaccRecordType { id, name } }
    Branch { id, name } 
  }
  BranchCategories { id, name }
}`;
const GetPetPayload = `{
  id, name, profile_pic, gender, PetColor{id,name}, type, birthdate, Breed { id, name },
  PetRecord ${GetPetRecordPayload}
}`;
// TODO: convert to fragment
export const CreateNewPet = gql`
mutation petAdd($name: String!, $type: PettypeEnumType!, $gender: String!, $breed: String!, $birthdate: Date!) {
  petAdd(Pet:{
    name: $name,
    type: $type,
    gender: $gender,
    BreedId: $breed,
    birthdate: $birthdate
  }) ${GetPetPayload}
}`;

export const GetPetProfile = gql`
query petGet($id: String) {
  petGet(id: $id) ${GetPetPayload}
}`;

export const GetUserProfile = gql`
  query getProfile {
    getProfile {
      id
      name
      username
      postcode
      addresses
      profile_pic
      preferences
      payment
      postcode
      email
      AppUserCredit {
        id
        amount
      }
    }
  }
`;

export const GetSearchBranches = gql`
  query searchBranches($name: String!, $categoryId: String, $visibility: String) {
    searchBranches(SearchBranchesInput: { name: $name, categoryId: $categoryId, visibility: $visibility }) {
      id
      photos
      address
      name
      colors
      latitude
      longitude
      email
      contact_number
      BranchTargeting {
        id
        visibility
        status
        pet_type
      }
    }
  }
`;

export const GetBranchCategoryByName = gql`
  query branchCategoryGet($name: String!) {
    branchCategoryGet(where: { name: $name }) {
      id
    }
  }
`;

export const ChatSubscription = gql`
  subscription onMessageAdded($ChatRooms: [String]!) {
    messageAdded(ChatRooms: $ChatRooms) {
      id
      ChatRoomId
      body
      notification_sent
      timestamp
      user_id
      user_type
      photos
      location
      video
    }
  }
`;

export const GetChatMessages = gql`
  query messageGet($ChatRoomId: String!) {
    messageGet(where: { ChatRoomId: $ChatRoomId }, order: "createdAt") {
      id
      ChatRoomId
      body
      notification_sent
      timestamp
      user_id
      user_type
      photos
      location
      video
    }
  }
`;

export const SendChatMessage = gql`
  mutation messageAdd(
    $id: String
    $body: String
    $ChatRoomId: String
    $notification_sent: Boolean
    $timestamp: Date
    $user_id: String
    $user_type: String
    $photos: [String]
    $location: SequelizeJSON
    $video: SequelizeJSON
  ) {
    messageAdd(
      Message: {
        id: $id
        body: $body
        ChatRoomId: $ChatRoomId
        notification_sent: $notification_sent
        timestamp: $timestamp
        user_id: $user_id
        user_type: $user_type
        photos: $photos
        location: $location
        video: $video
      }
    ) {
      id
    }
  }
`;

export const StartOrder = gql`
  mutation startOrder($items: [SequelizeJSON], $vouchers: [SequelizeJSON], $appointments: [SequelizeJSON], $subscriptions: [SequelizeJSON], $delivery: [SequelizeJSON]) {
    startOrder(StartOrderTypeInput: { items: $items, vouchers: $vouchers, appointments: $appointments, subscriptions: $subscriptions, delivery: $delivery }) {
      id
      Products {
        name
        id
      }
    }
  }
`;

export const StartMultiOrder = gql`
  mutation startMultiOrder($startMultiOrderInput: StartMultiOrderInput) {
    startMultiOrder(StartMultiOrderInput: $startMultiOrderInput) {
      id
    }
  }
`;

export const StartRecurringAppointmentOrder = gql`
  mutation startRecurringAppointmentOrder(
    $weeks_count: Int!
    $start_date: String!
    $day_of_week: [Int]!
    $PetRecordId: [String]!
    $ProductId: String!
    $customizations: SequelizeJSON
    $submitFormsAnswersInput: SubmitFormsAnswersInput
  ) {
    startRecurringAppointmentOrder(
      StartRecurringAppointmentOrderInput: {
        weeks_count: $weeks_count
        start_date: $start_date
        day_of_week: $day_of_week
        PetRecordId: $PetRecordId
        customizations: $customizations
        ProductId: $ProductId
        submitFormsAnswersInput: $submitFormsAnswersInput
      }
    ) {
      id
      Products {
        name
        id
      }
    }
  }
`;

export const GetPreOrderSummary = gql`
  query getPreOrderSummary($items: [SequelizeJSON], $vouchers: [SequelizeJSON], $appointments: [SequelizeJSON], $subscriptions: [SequelizeJSON], $delivery: [SequelizeJSON]) {
    getPreOrderSummary(GetPreOrderSummaryInput: { items: $items, vouchers: $vouchers, appointments: $appointments, subscriptions: $subscriptions, delivery: $delivery }) {
      discount
      appliedVouchers {
        id
        Discount {
          id
          rules
        }
      }
      finalPrices {
        finalPriceWithDeliveryAndCredits
      }
      isAlreadyBooked
      isAlreadySubscribed
      total
      productsWithTotals {
        productTotals {
          priceSummary
        }
      }
    }
  }
`;

export const GetMultiOrderPreOrderSummary = gql`
  query getMultiOrderPreOrderSummary($getMultiOrderPreOrderSummaryInput: GetMultiOrderPreOrderSummaryInput) {
    getMultiOrderPreOrderSummary(GetMultiOrderPreOrderSummaryInput: $getMultiOrderPreOrderSummaryInput) {
      summariesTotal {
        discount
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        total
      }
      summaries {
        discount
        isAlreadyBooked
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        appliedVouchers {
          id
          Discount {
            id
            rules
          }
        }
        total
        productsWithTotals {
          productTotals {
            priceSummary
          }
        }
      }
    }
  }
`;

export const GetRecurringAppointmentsPreOrderSummary = gql`
  query getRecurringAppointmentsPreOrderSummary($weeks_count: Int!, $start_date: String!, $day_of_week: [Int]!, $PetRecordId: [String]!, $ProductId: String!, $customizations: SequelizeJSON) {
    getRecurringAppointmentsPreOrderSummary(
      GetRecurringAppointmentsPreOrderSummaryInput: {
        weeks_count: $weeks_count
        start_date: $start_date
        day_of_week: $day_of_week
        PetRecordId: $PetRecordId
        ProductId: $ProductId
        customizations: $customizations
      }
    ) {
      summariesTotal {
        discount
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        total
      }
      summaries {
        discount
        isAlreadyBooked
        finalPrices {
          finalPriceWithDeliveryAndCredits
        }
        appliedVouchers {
          id
          Discount {
            id
            rules
          }
        }
        productsWithTotals {
          productTotals {
            priceSummary
          }
        }
        total
      }
      timestamps
    }
  }
`;

export const CancelBookingOrder = gql`
  mutation cancelOrder($orderId: String!) {
    cancelOrder(CancelOrderInput: { orderId: $orderId }) {
      message
    }
  }
`;

export const GetAllUserAppointments = gql`
  query getAllUserAppointments {
    getAllUserAppointments {
      id
      timestamp
      status
      PetRecord {
        id
        Pet {
          id
          profile_pic
        }
      }
      OrderItem {
        id
        Order {
          id
          total
          credit_used
          status
          number
          AppUser {
            id
          }
          Products {
            id
            name
            Branch {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GetAllUserOrderSubscriptions = gql`
  query getAllUserOrderSubscriptions {
    getAllUserOrderSubscriptions {
      id
      status
      OrderSubscriptionRepeats {
        id
        OrderItem {
          id
          item
        }
      }
    }
  }
`;

export const getAllUserOrders = gql`
  query fetchOrders {
    fetchOrders {
      id
      total
      number
      credit_used
      status
      createdAt
      delivery
      payment
      Products {
        id
        price
        charge_type
        name
        weight
        unit
        ProductKinds {
          id
          name
        }
      }
      Branch {
        id
        name
      }
    }
  }
`;

export const GetAppUserOrdersPaymentActionRequired = gql`
  query getAppUserOrdersPaymentActionRequired {
    getAppUserOrdersPaymentActionRequired {
      orderId
      userId
      paymentIntentClientSecret
      setupIntentClientSecret
      paymentIntentId
      paymentMethodId
    }
  }
`;

export const getProductOrderItems = gql`
  query getProductOrderItems($OrderId: [String]!) {
    getProductOrderItems(GetProductOrderItemsInput: { OrderId: $OrderId }) {
      id
      quantity
      OrderId
      ProductId
      customizations
      item
    }
  }
`;

export const GetProfileWithPets = gql`
  query getProfile {
    getProfile(order: "name") {
      id
      name
      profile_pic
      Pets(order: "name") {
        id
        name
        profile_pic
        PetRecord {
          id
        }
      }
      ChappedPets(order: "name") {
        id
        name
        profile_pic
        PetRecord {
          id
        }
      }
    }
  }
`;

export const GetVoucherByCode = gql`
  query getVoucherByCode($code: String!, $BranchId: String!, $ProductId: [String]!, $itemsTotal: Float, $petsCount: Int) {
    getVoucherByCode(GetVoucherByCodeInput: { code: $code, BranchId: $BranchId, itemsTotal: $itemsTotal, petsCount: $petsCount, ProductId: $ProductId }) {
      id
      code
      Discount {
        id
        rules
      }
    }
  }
`;

export const GetBranchCategoriesAndMealPortions = gql`
  query branchCategoryGet($petType: String) {
    branchCategoryGet(where: { or: [{ name: "Dry" }, { name: "Raw" }, { name: "Fresh" }, { name: "Wet" }] }) {
      id
      name
      picture
      Branches(limit: 100, order: "name") {
        id
        name
        BranchCategories {
          id
          name
        }
      }
    }
    mealPortionGet(order: "age", where: { pet_type: $petType }) {
      id
      age
      pet_type
      activity
      multiplier
      meal_count
      BranchCategoryId
    }
  }
`;

export const GetReceivedChapInvitations = gql`
  query chapInvitationGet($users: [String]!, $ChapUserId: String!) {
    chapInvitationGet(where: { AppUserId: $users, ChapUserId: $ChapUserId }, order: "updatedAt") {
      id
      status
      AppUserId
    }
  }
`;

export const GetHomeEvents = gql`
  fragment PetProfile on Pet {
    id
    profile_pic
    type
    name
    PetRecord {
      id
      Meals {
        id
        time
        quantity
        unit
        Branch {
          id
          name
        }
      }
      SuppRecords {
        id
        time
        createdAt
        adherences_allowed
        start_date
        quantity
        type
        Product {
          id
          name
          weight
          unit
          Branch {
            id
            name
          }
          ProductKinds {
            id
            name
          }
        }
        Adherences(limit: 1) {
          id
          timestamp
          status
        }
        MedConds {
          id
        }
      }
      VaccRecords {
        id
        name
        dates
        nextdate
        color
        VaccRecordType {
          id
          name
        }
        Adherences(limit: 1) {
          id
          timestamp
          status
        }
      }
    }
  }
  query getHomeEvents($timestamp_from: String) {
    getAllUserAppointments(GetAllUserAppointmentsInput: { status: "CONFIRMED", timestamp_from: $timestamp_from }) {
      id
      timestamp
      status
      PetRecord {
        id
        Pet {
          id
          name
          profile_pic
        }
      }
      OrderItem {
        id
        Order {
          id
          AppUser {
            id
          }
          Products {
            id
            name
            Branch {
              id
              name
            }
          }
        }
      }
    }
    getProfile(order: "name") {
      id
      preferences
      Pets(order: "name") {
        ...PetProfile
      }
      ChappedPets(order: "name") {
        ...PetProfile
        AppUser {
          id
          name
        }
      }
    }
  }
`;

export const GetWeather = gql`
  query getWeather {
    getWeather {
      weather {
        hourly {
          brief
          date
          temp
          icon
          background {
            colors
            stops
          }
          title
          description
          humidity
          pressure
          links {
            url
            description
          }
          linkRandomness
        }
      }
    }
  }
`;

export const UpdateChapInvitationStatus = gql`
  mutation chapInvitationEdit($id: String!, $status: ChapInvitationstatusEnumType!) {
    chapInvitationEdit(ChapInvitation: { id: $id, status: $status }) {
      id
      status
    }
  }
`;

export const DeletePet = gql`
  mutation deletePet($petId: String!) {
    deletePet(PetDeleteInput: { id: $petId }) {
      message
    }
  }
`;

export const DeleteChappedPet = gql`
  mutation deleteChap($petId: String!) {
    deleteChap(ChapDeleteInput: { PetId: $petId }) {
      message
    }
  }
`;

export const GetVaccRecordTypes = gql`
  query vaccRecordTypeGet {
    vaccRecordTypeGet {
      id
      name
    }
  }
`;

export const CreatePetVaccRecord = gql`
  mutation vaccRecordAdd($color: String!, $dates: [Date]!, $nextdate: Date, $name: String!, $VaccRecordTypeId: String!, $PetRecordId: String!, $batch_number: String) {
    vaccRecordAdd(VaccRecord: { color: $color, dates: $dates, nextdate: $nextdate, name: $name, VaccRecordTypeId: $VaccRecordTypeId, PetRecordId: $PetRecordId, batch_number: $batch_number }) {
      id
      name
      dates
      nextdate
      color
      batch_number
      VaccRecordType {
        id
        name
      }
    }
  }
`;

export const DeleteUserAccount = gql`
  mutation deleteUserAccount {
    deleteUserAccount {
      message
    }
  }
`;

export const UpdateUserInfo = gql`
  mutation updateAppUser(
    $postcode: String
    $name: String
    $username: String
    $addresses: [SequelizeJSON]
    $profile_pic: String
    $preferences: SequelizeJSON
    $payment: SequelizeJSON
    $locale: SequelizeJSON
  ) {
    updateAppUser(AppUser: { postcode: $postcode, name: $name, username: $username, addresses: $addresses, profile_pic: $profile_pic, preferences: $preferences, payment: $payment, locale: $locale }) {
      id
      name
      username
      postcode
      addresses
      profile_pic
      preferences
      payment
      postcode
      email
    }
  }
`;

export const StartSupportChat = gql`
  mutation startSupportChat($branchId: String) {
    startSupportChat(StartSupportChatInput: { branchId: $branchId }) {
      id
    }
  }
`;

export const GetEnumTypes = gql`
  query getEnumTypes {
    __schema {
      types {
        name
        enumValues {
          name
        }
      }
    }
  }
`;

export const EditPetPhoto = gql`
  mutation petEdit($id: String, $cover_photo: String) {
    petEdit(Pet: { id: $id, profile_pic: $cover_photo }) {
      id
      profile_pic
    }
  }
`;

export const EditRoomPhoto = gql`
  mutation chatRoomEdit($id: String, $cover_photo: String) {
    chatRoomEdit(ChatRoom: { id: $id, cover_photo: $cover_photo }) {
      id
      cover_photo
    }
  }
`;

export const GetUserProfileHome = gql`
  query getProfile {
    getProfile {
      id
      Pets(order: "name") {
        id
        name
        profile_pic
      }
      ChappedPets(order: "name") {
        id
        name
        profile_pic
      }
      name
      username
      profile_pic
    }
  }
`;

export const UpdateChap = gql`
  mutation updateChap($chapId: String!, $pets: [SequelizeJSON!]!) {
    updateChap(UpdateChapInput: { chapId: $chapId, pets: $pets }) {
      id
      end_date
      PetId
      AppUserId
    }
  }
`;

export const getSentFromUserChapInvitations = gql`
  query getProfile($AppUserId: String) {
    getProfile {
      id
      ChapInvitations {
        id
        name
        profile_pic
        ChappedPets(where: { AppUserId: $AppUserId }, order: "name") {
          name
          id
          profile_pic
        }
      }
    }
  }
`;

export const RemoveChap = gql`
  mutation deleteChapInvitation($chapId: String!, $petIds: [String]!) {
    deleteChapInvitation(ChapInvitationDeleteInput: { id: $chapId, petIds: $petIds }) {
      message
    }
  }
`;

export const GetUserChapInvitations = gql`
  query getProfile {
    getProfile {
      id
      ChapInvitations {
        id
      }
    }
  }
`;

export const GetAppUserByEmail = gql`
  query getAppUserByEmail($email: String!) {
    getAppUserByEmail(AppUserEmailInput: { email: $email }) {
      id
      name
      email
      profile_pic
    }
  }
`;

export const getAppUserByUsername = gql`
  query getAppUserByUsername($username: String!) {
    getAppUserByUsername(AppUserUsernameInput: { username: $username }) {
      id
      name
      username
      profile_pic
    }
  }
`;

export const InviteChap = gql`
  mutation chapInvitationAdd($ChapUserId: String) {
    chapInvitationAdd(ChapInvitation: { ChapUserId: $ChapUserId }) {
      id
    }
  }
`;

export const GetProductsForOrder = gql`
  query productGet($cartIds: [String]) {
    productGet(where: { id: $cartIds }) {
      id
      name
      type
      unit
      price
      charge_type
      weight
      ProductKinds {
        name
      }
      Branch {
        id
      }
    }
  }
`;

export const GetProductsForDelivery = gql`
  query productGet($cartIds: [String]) {
    productGet(where: { id: $cartIds }) {
      id
      price
      charge_type
      Branch {
        id
        name
        delivery_rules
      }
      customizations
      future_delivery
      weight
    }
  }
`;

export const CreateChatRoom = gql`
  mutation chatRoomAdd($name: String) {
    chatRoomAdd(ChatRoom: { name: $name }) {
      id
      name
    }
  }
`;

export const AddUserToChat = gql`
  mutation appUserChatConnectionAdd($AppUserId: String!, $ChatRoomId: String!) {
    appUserChatConnectionAdd(AppUserChatConnection: { AppUserId: $AppUserId, ChatRoomId: $ChatRoomId }) {
      id
    }
  }
`;

export const GetChatRoomUsers = gql`
  query chatRoomGet($id: String!) {
    chatRoomGet(where: { id: $id }) {
      AppUsers {
        id
      }
    }
  }
`;

export const UpdateUserName = gql`
  mutation updateAppUser($username: String) {
    updateAppUser(AppUser: { username: $username }) {
      id
    }
  }
`;

export const GetAllBreeds = gql`
  query breedGet {
    breedGet(order: "name") {
      type
      name
      id
    }
  }
`;

export const GetPetColors = gql`
  query petColorGet {
    petColorGet(order: "name") {
      type
      name
      id
    }
  }
`;

export const GetProductKinds = gql`
  query productKindGet {
    productKindGet {
      id
      name
    }
  }
`;

export const GetBranchesByProductKind = gql`
  query getBranchesByProductKind($productKindId: String!) {
    getBranchesByProductKind(ProductKindIdInput: { id: $productKindId }) {
      id
      name
    }
  }
`;

export const GetProductsByBranchId = gql`
  query productGet($branchId: String!) {
    productGet(where: { BranchId: $branchId }) {
      id
      name
      type
      unit
      price
      charge_type
      weight
    }
  }
`;

export const GetServicesByBranchId = gql`
  query productGet($branchId: String!) {
    productGet(where: { BranchId: $branchId, type: "service" }) {
      id
      name
      type
      unit
      price
      charge_type
      weight
      description
    }
  }
`;

export const AddSupplement = gql`
  mutation suppRecordAdd($type: String!, $product: String, $quantity: String!, $time: String!, $PetRecordId: String!, $start_date: Date, $adherences_allowed: Boolean) {
    suppRecordAdd(SuppRecord: { type: $type, ProductId: $product, quantity: $quantity, time: $time, PetRecordId: $PetRecordId, start_date: $start_date, adherences_allowed: $adherences_allowed }) {
      id
      time
      createdAt
      quantity
      type
      adherences_allowed
      start_date
      Product {
        id
        name
        weight
        unit
        Branch {
          id
          name
        }
        ProductKinds {
          id
          name
        }
      }
    }
  }
`;

export const UpdateMeals = gql`
  mutation updateMeals($meals: [SequelizeJSON!]!, $categories: [String]!, $weight: String) {
    updateMeals(UpdateMealsInput: { meals: $meals, categories: $categories, weight: $weight }) {
      id
      time
      quantity
      unit
      Branch {
        id
        name
      }
    }
  }
`;

export const GetAllBranches = gql`
  query getBranches($id: String, $offset: Int, $limit: Int, $lat: String, $lng: String, $categoryId: String, $visibility: String) {
    getBranches(limit: $limit, offset: $offset, GetBranchesInput: { id: $id, lat: $lat, lng: $lng, categoryId: $categoryId, visibility: $visibility }) {
      id
      photos
      address
      name
      colors
      latitude
      longitude
      terms_and_conditions_url
      privacy_policy_url
      BranchTargeting {
        id
        visibility
        status
        pet_type
      }
    }
  }
`;

export const GetBranchById = gql`
  query getBranchById($id: String!) {
    getBranchById(GetBranchByIdInput: { id: $id }) {
      id
      name
      photos
      colors
      booking_slots
      terms_and_conditions_url
      privacy_policy_url
      analytics
      address
      branchCurrency {
        currency
        symbol
      }
      BranchTargeting {
        id
        visibility
        status
        pet_type
      }
      Products(where: { or: [{ type: "service" }, { type: "subscription" }] }) {
        id
        name
        description
        short_description
        original_price
        additional_info
        allows_repeated_orders
        show_customizations_price
        allows_bulk_orders
        max_bulk_orders
        payment
        price
        charge_type
        unit
        status
        type
        BranchId
        Branch {
          id
          name
        }
        use_pets_count
        Flags {
          id
          name
        }
        cta_text
        stock
        duration
        sort_index
        slots_start_date
        slots_recurrence
        booking_type
        slots
        customizations
        sessions
        SubscriptionProducts {
          id
        }
      }
    }
  }
`;

export const GetProviderById = gql`
  query getProviderById($id: String!) {
    getProviderById(GetProviderByIdInput: { id: $id }) {
      id
      name
      Branches {
        id
        name
        photos
        colors
        booking_slots
        terms_and_conditions_url
        privacy_policy_url
        analytics
        Products(where: { or: [{ type: "service" }, { type: "subscription" }] }) {
          id
          name
          description
          short_description
          original_price
          additional_info
          allows_repeated_orders
          show_customizations_price
          allows_bulk_orders
          max_bulk_orders
          payment
          price
          charge_type
          unit
          status
          type
          BranchId
          Branch {
            id
            name
          }
          use_pets_count
          Flags {
            id
            name
          }
          cta_text
          stock
          duration
          sort_index
          slots_start_date
          slots_recurrence
          booking_type
          slots
          customizations
          sessions
          SubscriptionProducts {
            id
          }
        }
      }
    }
  }
`;

export const SearchBranches = gql`
  query searchBranches($name: String!, $visibility: String) {
    searchBranches(SearchBranchesInput: { name: $name, visibility: $visibility }) {
      id
      photos
      address
      name
      colors
      latitude
      longitude
      BranchTargeting {
        id
        visibility
        status
        pet_type
      }
    }
  }
`;

export const GetUserPreferences = gql`
  query getProfile {
    getProfile {
      id
      preferences
    }
  }
`;

export const EditPetProfile = gql`
  mutation petEdit($id: String, $name: String, $type: PettypeEnumType, $gender: String, $breed: String, $color: String, $birthdate: Date) {
    petEdit(Pet: { id: $id, name: $name, type: $type, gender: $gender, BreedId: $breed, birthdate: $birthdate, PetColorId: $color }) {
      id
      name
      type
      gender
      Breed {
        id
        name
      }
      birthdate
      PetColor {
        id
        name
      }
    }
  }
`;

export const EditPetRecord = gql`
  mutation petRecordEdit(
    $id: String
    $measurements: SequelizeJSON
    $shape: PetRecordshapeEnumType
    $neutred: Boolean
    $allergies: SequelizeJSON
    $chronic_conditions: SequelizeJSON
    $disabilities: SequelizeJSON
    $microchip_number: String
    $microchip_provider: String
    $passport_number: String
  ) {
    petRecordEdit(
      PetRecord: {
        id: $id
        shape: $shape
        neutred: $neutred
        measurements: $measurements
        allergies: $allergies
        chronic_conditions: $chronic_conditions
        disabilities: $disabilities
        microchip_number: $microchip_number
        microchip_provider: $microchip_provider
        passport_number: $passport_number
      }
    ) {
      id
      shape
      neutred
      measurements
      allergies
      chronic_conditions
      disabilities
      microchip_number
      microchip_provider
      passport_number
    }
  }
`;

export const GetCardAuthentication = gql`
  query getCardAuthentication {
    getCardAuthentication {
      clientSecret
      status
      ephemeralKey
      customerId
    }
  }
`;

export const HandleOrderPaymentAfterAction = gql`
  mutation handleOrderPaymentAfterAction($orderId: String!, $paymentIntentId: String, $failure: String, $paymentMethodId: String) {
    handleOrderPaymentAfterAction(HandleOrderPaymentAfterActionInput: { orderId: $orderId, paymentIntentId: $paymentIntentId, failure: $failure, paymentMethodId: $paymentMethodId }) {
      id
    }
  }
`;

export const CheckBranchAppointmentAvailability = gql`
  query checkBranchAppointmentAvailability($branchId: String!, $productId: String, $customizations: [SequelizeJSON], $slots_length: Int) {
    checkBranchAppointmentAvailability(CheckBranchAppointmentAvailabilityInput: { branchId: $branchId, productId: $productId, customizations: $customizations, slots_length: $slots_length }) {
      slots
      dates
    }
  }
`;

export const AddAdherence = gql`
  mutation adherenceAdd($PetRecordId: String!, $SuppRecordId: String, $VaccRecordId: String, $timestamp: Date!, $MedCondId: String) {
    adherenceAdd(Adherence: { PetRecordId: $PetRecordId, SuppRecordId: $SuppRecordId, VaccRecordId: $VaccRecordId, MedCondId: $MedCondId, timestamp: $timestamp, status: true }) {
      id
      status
      PetRecordId
      SuppRecordId
      timestamp
    }
  }
`;

export const EditVaccination = gql`
  mutation vaccRecordEdit($id: String!, $color: String, $dates: [Date], $nextdate: Date, $name: String, $VaccRecordTypeId: String, $PetRecordId: String, $batch_number: String) {
    vaccRecordEdit(
      VaccRecord: { id: $id, color: $color, dates: $dates, nextdate: $nextdate, name: $name, VaccRecordTypeId: $VaccRecordTypeId, PetRecordId: $PetRecordId, batch_number: $batch_number }
    ) {
      id
      name
      dates
      nextdate
      color
      batch_number
      VaccRecordType {
        id
        name
      }
    }
  }
`;

export const EditSupplement = gql`
  mutation suppRecordEdit($id: String!, $type: String, $product: String, $quantity: String, $time: String, $PetRecordId: String, $start_date: Date, $adherences_allowed: Boolean) {
    suppRecordEdit(
      SuppRecord: { id: $id, type: $type, ProductId: $product, quantity: $quantity, time: $time, PetRecordId: $PetRecordId, start_date: $start_date, adherences_allowed: $adherences_allowed }
    ) {
      id
      time
      createdAt
      quantity
      type
      adherences_allowed
      start_date
      Product {
        id
        name
        weight
        unit
        Branch {
          id
          name
        }
        ProductKinds {
          id
          name
        }
      }
    }
  }
`;

export const GetBranchPetRecordItems = gql`
  query branchPetRecordItemGet($PetRecordId: String!, $type: BranchPetRecordItemtypeEnumType) {
    branchPetRecordItemGet(where: { PetRecordId: $PetRecordId, type: $type }) {
      id
      BranchId
      type
      days
    }
  }
`;
export const AddBranchPetRecordItem = gql`
  mutation branchPetRecordItemAdd($branchId: String!, $PetRecordId: String!, $type: BranchPetRecordItemtypeEnumType, $days: [String]) {
    branchPetRecordItemAdd(BranchPetRecordItem: { PetRecordId: $PetRecordId, BranchId: $branchId, type: $type, days: $days }) {
      id
      PetRecordId
      BranchId
      type
      days
    }
  }
`;
export const DeleteBranchPetRecordItem = gql`
  mutation deleteBranchPetRecordItem($id: String!) {
    deleteBranchPetRecordItem(DeleteBranchPetRecordItemInput: { id: $id }) {
      message
    }
  }
`;

export const GetProductById = gql`
  query productGet($id: String!) {
    productGet(where: { id: $id }) {
      id
      name
      BranchId
      sort_index
      price
      charge_type
      description
      duration
      unit
      type
      photos
      ProductKinds {
        id
        name
      }
      customizations
      cta_text
      Flags {
        id
        name
      }
      status
      stock
      Branch {
        id
        photos
        address
        name
        colors
        latitude
        longitude
        BranchTargeting {
          id
          visibility
          status
          pet_type
        }
      }
    }
  }
`;

export const GetProfilePetsCount = gql`
  query getProfile {
    getProfile {
      id
      Pets(order: "name") {
        id
      }
      ChappedPets {
        id
      }
    }
  }
`;

export const GetBranchCategoriesByName = gql`
  query getBranchCategories($name: String!) {
    branchCategoryGet(order: "name", where: { status: "PARENT", name: $name }) {
      id
      name
      picture
    }
  }
`;

export const GetProductsByBranchCategory = gql`
  query getProductsByBranchCategory($branchCategoryId: String) {
    getProductsByBranchCategory(limit: 100, GetProductsByBranchCategoryInput: { BranchCategoryId: $branchCategoryId, status: ["IN_STOCK"] }) {
      id
      name
      BranchId
      sort_index
      price
      charge_type
      description
      duration
      unit
      type
      photos
      ProductKinds {
        id
        name
      }
      customizations
      cta_text
      Flags {
        id
        name
      }
      status
      stock
      ProductCategories {
        id
        name
      }
      Branch {
        id
        photos
        address
        name
        colors
        latitude
        longitude
        BranchTargeting {
          id
          visibility
          status
          pet_type
        }
      }
    }
  }
`;

export const GetAllProducts = gql`
  query productGet {
    productGet(limit: 100, where: { status: "IN_STOCK" }) {
      id
      name
      BranchId
      sort_index
      price
      charge_type
      description
      duration
      unit
      type
      photos
      ProductKinds {
        id
        name
      }
      customizations
      cta_text
      Flags {
        id
        name
      }
      status
      stock
      ProductCategories {
        id
        name
      }
      Branch {
        id
        photos
        address
        name
        colors
        latitude
        longitude
        BranchTargeting {
          id
          visibility
          status
          pet_type
        }
      }
    }
  }
`;

export const GetProductKindsWithProducts = gql`
  query productKindGet($name: [String]) {
    productKindGet(limit: 100, where: { name: $name }, order: "name") {
      id
      name
      Products(limit: 100) {
        id
        name
      }
    }
  }
`;

export const UpdateMessageReadStatus = gql`
  ${MESSAGE_READ_STATUS_FRAGMENT}
  mutation updateMessageReadStatus($status: MessageReadStatusstatusEnumType, $last_read_message: String, $last_read_timestamp: Date, $ChatRoomId: String) {
    updateMessageReadStatus(MessageReadStatus: { status: $status, last_read_message: $last_read_message, last_read_timestamp: $last_read_timestamp, ChatRoomId: $ChatRoomId }) {
      ...MessageReadStatusFragment
    }
  }
`;

export const GetUnreadRoomsCount = gql`
  query getUnreadRoomsCount {
    getUnreadRoomsCount {
      count
    }
  }
`;

export const GetRequiredForms = gql`
  query getRequiredFormsForOrder($ProductId: [String]!, $PetRecordId: [String]!) {
    getRequiredFormsForOrder(GetRequiredFormsForOrderInput: { ProductId: $ProductId, PetRecordId: $PetRecordId }) {
      id
      name
    }
  }
`;

export const GetReducedBranchForms = gql`
  query getForms($branchId: String) {
    getForms(GetFormsInput: { branchId: $branchId }) {
      id
      name
    }
  }
`;

export const GetForms = gql`
  ${BRANCH_FORM_FRAGMENT}
  query getForms($branchId: String, $id: [String]) {
    getForms(GetFormsInput: { id: $id, branchId: $branchId }) {
      ...BranchFormFragment
    }
  }
`;

export const SubmitFormAnswers = gql`
  mutation submitFormAnswers($answers: SequelizeJSON!) {
    submitFormAnswers(SubmitFormAnswersInput: { answers: $answers }) {
      id
    }
  }
`;

export const SubmitFormsAnswers = gql`
  mutation submitFormsAnswers($submitFormsAnswersInput: SubmitFormsAnswersInput) {
    submitFormsAnswers(SubmitFormsAnswersInput: $submitFormsAnswersInput) {
      id
    }
  }
`;

export const GetAllCountries = gql`
  query countryGet {
    countryGet(limit: 1000) {
      id
      name
    }
  }
`;

export const GetSlots = gql`
  query getSlots($branchId: String!, $productId: String, $customizations: [SequelizeJSON], $slots_length: Int) {
    getSlots(GetSlotsInput: { branchId: $branchId, productId: $productId, customizations: $customizations, slots_length: $slots_length }) {
      slots
    }
  }
`;
